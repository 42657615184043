import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/Clients/StephaneCatoire/Gastby/scatoire-perso/src/components/blog-post-layout.jsx";
import { graphql } from "gatsby";
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Ecris un article sur "Comment utiliser une IA pour écrire un article de blog" mots-clé:paresseux,référencement,blog,moderne,bluffant,`}</p>
    <h1>{`Introduction`}</h1>
    <p>{`Si vous êtes paresseux et que vous voulez référencer votre blog, une IA est la solution idéale pour vous ! Grâce à une IA, vous pouvez écrire des articles de blog bluffants et modernes sans avoir à vous fatiguer.`}</p>
    <h1>{`Le modele GPT-3`}</h1>
    <p>{`Le modèle GPT-3 est l’un des modèles de machine learning les plus populaires pour l’écriture automatique. Il est basé sur l’apprentissage profond et permet de générer du texte de manière semi-automatique. Pour utiliser ce modèle, vous devez tout d’abord créer un fichier de données qui contient les exemples de texte que vous souhaitez reproduire. Ensuite, vous devez utiliser un logiciel de machine learning pour transformer ces données en un modèle GPT-3. Pour utiliser le modèle GPT-3 avec votre blog, vous pourrez consulter le site beta.openai.com/gpt-3. Ce site contient une documentation détaillée sur la manière d’utiliser ce modèle.`}</p>
    <h1>{`Conclusion mots-clé:facile,immoral,où va le monde?`}</h1>
    <p>{`Si l'utilisation d'une IA pour écrire des articles de blog est facile, cette pratique est aussi immorale. Où va le monde si les humains ont de moins en moins besoin de travailler?`}</p>
    <p>{`C'est vrai, ça, pourquoi se casser le bas du dos si on peut faire bosser un I.A. à sa place ? Bon, Stéphane back on the mike (or the keyboard in that case), et tout ceux qui me répondent "Ha bon? On avait pas remarqué!" peuvent aller se faire cuire le fondement, incapables qu'ils sont d'apprécier mon style inégalable.`}</p>
    <p>{`Cela étant dit, je voulais m'amuser avec OPEN AI, d'où la petite introduction de mon article, et ce que j'ai découvert ma bluffé. Maintenant je sais d'où proviennent les articles putaclics qui m'obligent à visionner 50 pages de contenu complètement creux avant de voir à quoi ressemble Pamela Anderson de nos jours.`}</p>
    <p>{`Vous pouvez allez faire un tour sur OpenAI et de mon côté, je vais approfondir un peu la chose, peut-être un générateur automatique de mail à envoyer à un boss à 21 heures pour lui faire croire qu'on est en train de bosser dur (cher recruteur, si vous lisez cette partie, n'allez surtout pas croire que je suis un optimisateur de temps de travail -et de repos- c'est pour m'attirer la sympathie du lecteur) ou bien un programme pour répondre automatiquement à une mauvaise critque postée sur Google Maps.`}</p>
    <p>{`Notez tout de même que pour utiliser cette API, il vous faudra vous enregistrer. Vous pourrez jouer avec, mais une utilisation professionelle est bien entendu payante.`}</p>
    <p>{`A bientôt donc, cher et aimé public !`}</p>
    <h1>{`Post-scriptum`}</h1>
    <p>{`Hé, on rigole tout ça, mais regardez quand même ce que ça donne en vrai. La partie entourée de *** est l'amorce que j'ai donné au système et le reste vient d'un androïde qui rêve de moutons électriques`}</p>
    <pre><code parentName="pre" {...{}}>{`*** to: Boss
concerns: Status update about project Catoire

Dear sir I write this mail to inform you*** that the project Catoire is progressing as planned. 
The team is working hard to meet the deadline and we are sure that we will be able to deliver the project on time.
Thank you for your concern.
Best regards
Project Manager
`}</code></pre>
    {
      /* Footer to allow translation to be copied on each post
          Boring but wainting for a better solution */
    }



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      